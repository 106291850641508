import {BaseComponentModel, BaseEditorComponent, ConnectedComponentModel} from './base-editor-component';
import {ConnectionBuilderFactory} from '../manifest/manifest-definitions';

enum StaffListComponentIds {
  STAFF_IMAGE = 'staffImage',
  STAFF_NAME = 'staffName',
  STAFF_DESCRIPTION = 'staffDescription',
  STAFF_DETAILS = 'staffDetails',
  STAFF_BUTTON = 'staffButton',
  STAFF_REPEATER = 'staffRepeater',
  STAFF_CONTAINER = 'staffContainer',
  STAFF_DIVIDER = 'staffDivider',
  STAFF_BUTTON_CONTAINER = 'staffButtonContainer',
}

export class StaffListEditorComponentModel extends BaseEditorComponent{
  constructor(editorSdkAdapter, editorTranslation, {manageStaffAction, staffListSettingsAction, openStaffListTextSettingsAction}) {
    const connectionFactory = new ConnectionBuilderFactory(editorTranslation);
    const connectedComponents = [
      new ConnectedComponentModel(StaffListComponentIds.STAFF_REPEATER, connectionFactory.repeater(editorTranslation.t('bookings.staff-list.component.label.StaffRepeater'), '8a5134e7-7689-4772-acc4-fb2a9830b54e', {mainAction1: manageStaffAction, mainAction2: staffListSettingsAction, shouldHideActionsOnMobile: true})),
      new ConnectedComponentModel(StaffListComponentIds.STAFF_CONTAINER, connectionFactory.repeaterItem(editorTranslation.t('bookings.staff-list.component.label.StaffContainer'), '8a5134e7-7689-4772-acc4-fb2a9830b54e', {mainAction2: staffListSettingsAction, shouldHideActionsOnMobile: true})),
      new ConnectedComponentModel(StaffListComponentIds.STAFF_DIVIDER, connectionFactory.divider(editorTranslation.t('bookings.staff-list.component.label.StaffDivider'))),
      new ConnectedComponentModel(StaffListComponentIds.STAFF_BUTTON, connectionFactory.link(editorTranslation.t('bookings.staff-list.component.label.StaffButton'), '8a5134e7-7689-4772-acc4-fb2a9830b54e', {mainAction1: openStaffListTextSettingsAction})),
      new ConnectedComponentModel(StaffListComponentIds.STAFF_DETAILS, connectionFactory.uneditableText(editorTranslation.t('bookings.staff-list.component.label.StaffDetails'), '')),
      new ConnectedComponentModel(StaffListComponentIds.STAFF_DESCRIPTION, connectionFactory.uneditableText(editorTranslation.t('bookings.staff-list.component.label.StaffDescription'), '')),
      new ConnectedComponentModel(StaffListComponentIds.STAFF_NAME, connectionFactory.uneditableText(editorTranslation.t('bookings.staff-list.component.label.StaffName'), '')),
      new ConnectedComponentModel(StaffListComponentIds.STAFF_IMAGE, connectionFactory.image(editorTranslation.t('bookings.staff-list.component.label.StaffImage'), '', {mainAction2: staffListSettingsAction, shouldHideActionsOnMobile: true})),
      new ConnectedComponentModel(StaffListComponentIds.STAFF_BUTTON_CONTAINER, connectionFactory.unselectableBox()),
    ];
    const componentModel = new BaseComponentModel({
      nickname: 'staffList',
      label: editorTranslation.t('bookings.staff-list.component.label.StaffList'),
      mainAction1: manageStaffAction,
      mainAction2: staffListSettingsAction,
      doubleClickAction: staffListSettingsAction,
      helpId: '8a5134e7-7689-4772-acc4-fb2a9830b54e',
      connectedComponents,
      additionalConfiguration: { shouldHideMainAction2OnMobile: true }
    });
    super(editorSdkAdapter, componentModel);
  }
}
