import {getEditorSdkSrc} from './editor-sdk-actions';
import {handleMigration} from './migrate-actions';

const BOOKINGS_URL_PREFIX = 'https://bookings.wixapps.net';
const BOOKINGS_EDITOR_MODALS = 'bookings-editor-modals';

const handleMigrationProposalResult = async (migrationProposalResult, editorSdk, wixEditorSdkSrc, locale, appToken, instance, translation) => {
  if (migrationProposalResult.status && migrationProposalResult.status === 'confirmed') {
    try {
      await handleMigration(editorSdk, appToken, translation);
      await editorSdk.document.save();
      await openMigrationSucceedModal(editorSdk, wixEditorSdkSrc, locale, instance);
    } catch (e) {
      console.log('failed to migrate bookings', e);
      await openMigrationFailedModal(editorSdk, wixEditorSdkSrc, locale, instance);
    }
  }
};

const openModal = ({editorSdk, wixEditorSdkSrc, locale, modal, width, height, instance}) => {
  return editorSdk.editor.openModalPanel('token', {
    url: `${BOOKINGS_URL_PREFIX}/${BOOKINGS_EDITOR_MODALS}?wixEditorSdkSrc=${wixEditorSdkSrc}&modal=${modal}&locale=${locale}&instance=${instance}`,
    shouldHideHeader: true,
    width,
    height
  });
};

const openMigrationProposalModal = (editorSdk, wixEditorSdkSrc, locale, instance) =>
  openModal({editorSdk, wixEditorSdkSrc, locale, instance, modal: 'split_migration_proposal', width: 504, height: 486});

const openMigrationFailedModal = (editorSdk, wixEditorSdkSrc, locale, instance) =>
  openModal({editorSdk, wixEditorSdkSrc, locale, instance, modal: 'split_migration_failure', width: 564, height: 284});

const openMigrationSucceedModal = (editorSdk, wixEditorSdkSrc, locale, instance) =>
  openModal({editorSdk, wixEditorSdkSrc, locale, instance, modal: 'split_migration_success', width: 504, height: 486});

export const proposeMigration = async (editorSdk, appToken, locale, instance, translation) => {
  const wixEditorSdkSrc = getEditorSdkSrc(editorSdk);
  const migrationProposalResult = await openMigrationProposalModal(editorSdk, wixEditorSdkSrc, locale, instance);
  await handleMigrationProposalResult(migrationProposalResult, editorSdk, wixEditorSdkSrc, locale, appToken, instance, translation);
};
